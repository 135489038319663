<template>
    <DashboardTemplate>
        
    </DashboardTemplate>
</template>
<script>
import DashboardTemplate from '@/template/DashboardTemplate'
export default {
    components:{
        DashboardTemplate
    }
}
</script>